<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item> 支付订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体卡片视图区域 -->
    <el-card class="box-card">
      <!-- 上栅格布局  -->
      <el-row :gutter="10">

        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <!--          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialog =true" v-has="'POST_UserPermission'">添加</el-button>-->

          <el-button type="primary" icon="el-icon-arrow-left" @click="back()">返回列表</el-button>
          <el-button type="success" icon="el-icon-refresh-right" @click="update()">刷新</el-button>

        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-button type="primary" icon="el-icon-video-play" @click="addDialog=true" v-has="'POST_refund'">退款</el-button>
        </el-col>
      </el-row>

      <!-- 下渲染列表区域 -->
      <transition name="el-fade-in-linear">
        <!--        <el-table :data="DevicesList" border stripe>-->
        <!--          <el-table-column type="index"></el-table-column>-->
        <!--          <el-table-column label="ID" sortable prop="id"></el-table-column>-->
        <!--          &lt;!&ndash;          <el-table-column label="验证合法" prop="isValid"></el-table-column>&ndash;&gt;-->
        <!--          <el-table-column label="创建时间" sortable prop="createTime" :formatter="dateFormat"></el-table-column>-->
        <!--          <el-table-column label="更新时间" sortable prop="lastUpdateTime" :formatter="dateFormat"></el-table-column>-->
        <!--          <el-table-column label="平台名称"  prop="pingtaimingcheng" ></el-table-column>-->
        <!--          <el-table-column label="地址"  prop="dizhi" ></el-table-column>-->
        <!--          <el-table-column label="客服电话" prop="kefudianhua"></el-table-column>-->
        <!--          <el-table-column label="到期时间" sortable prop="daoqishijian" :formatter="dateFormat"></el-table-column>-->
        <!--          &lt;!&ndash;          <el-table-column label="创建协调时间" sortable prop="isEditPingtaisetting" :formatter="dateFormat"></el-table-column>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <el-table-column label="修改协调时间" sortable prop="UtcModified" :formatter="dateFormat"></el-table-column>&ndash;&gt;-->
        <!--          <el-table-column label="操作" width="310px">-->
        <!--            <template v-slot="scope">-->
        <!--              &lt;!&ndash;             <pre> {{scope.row}}</pre>&ndash;&gt;-->
        <!--              <el-button type="primary" icon="el-icon-search"  @click="showCheckDialog(scope.row.id)" v-has="'GET_PingtaiPermission'">查看</el-button>-->
        <!--              <el-button type="warning" icon="el-icon-setting"  @click="showEditDialog(scope.row.id)" v-has="'PUT_PingtaiPermission'">编辑</el-button>-->
        <!--              <el-button type="danger" icon="el-icon-delete" v-has="'DELETE_UserPermission'">删除</el-button>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </el-table>-->


        <el-table :show-header="false"
                  :data="tableData1()"
                  border
                  style="width: 100%; margin-top: 20px"
        >


          <el-table-column label="1" width="100px" prop="column1"></el-table-column>
          <el-table-column label="2" prop="column2"></el-table-column>
          <el-table-column label="3" width="100px" prop="column3"></el-table-column>
          <el-table-column label="4" prop="column4"></el-table-column>

        </el-table>

      </transition>


      <!--      <div >-->
      <!--        <h2>设备状态</h2>-->
      <!--        <div class="status">-->
      <!--          <el-switch-->
      <!--              active-text="禁用"-->
      <!--              v-model="editForm.isdisable"-->
      <!--              active-color="#13ce66"-->
      <!--              inactive-color="#ff4949">-->
      <!--          </el-switch>-->
      <!--        </div>-->
      <!--        <div class="status">-->
      <!--          <el-switch-->
      <!--              active-text="在线"-->
      <!--              v-model="editForm.isonline"-->
      <!--              active-color="#13ce66"-->
      <!--              inactive-color="#ff4949">-->
      <!--          </el-switch>-->
      <!--        </div>-->

      <!--      </div>-->


      <!-- 退款，对话框 -->
      <el-dialog
          title="退款"
          :visible.sync="addDialog"
          width="50%"
          @close="addDialogClosed"
      >
        <!-- 内容主题区域 -->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef"  label-width="150px">





          <el-form-item label="退款赠送金额" prop="refundtotalzengsongjine">
<!--            <el-select style="width: 80%" v-model="addForm.refundtype" placeholder="请选择">-->
<!--              <el-option v-for="(item,key) in select" :key="key" :label="item.label" :value="item.value"></el-option>-->
<!--            </el-select>-->
            <el-input-number  style="width: 80%" v-model="addForm.refundtotalzengsongjine" placeholder="请填写" :precision="2"></el-input-number>
          </el-form-item>


          <!--                <span>Selected: {{ addForm.ls }}</span>-->

          <el-form-item label="退款总金额" prop="refundtotal" >
            <el-input-number  style="width: 80%" v-model="addForm.refundtotal" placeholder="请填写" :precision="2"></el-input-number>
          </el-form-item>



          <el-form-item label="备注" prop="reason" >
            <el-input style="width: 80%"  v-model="addForm.reason" placeholder="请填写" ></el-input>
          </el-form-item>




        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
    <el-button @click="addDialog = false">取 消</el-button>
    <el-button type="primary" @click="refund">确 定</el-button>
  </span>
      </el-dialog>
      <!--二维码画布-->
      <canvas id="myCanvas" width="150" height="180" ref="code"
              style="border:1px solid #000000; display: none">
        <span>编号： {{this.$route.query.id}}</span>
      </canvas>

      <!--  二维码主体  -->
      <canvas style="display: none" id="qrImg" ref="code"></canvas>



      <!--    二维码区域 vue-qr   -->
      <div>

        <vue-qr
            style="display: none"
            id="qrImg2"
            :text="imgUrl"
            :size="250"
            :logoSrc="logo"
            :logoScale="0.2">
        </vue-qr>
        <!--    设备编号id    -->


      </div>


      <!-- 分页区域 -->

      <!--      <el-pagination-->
      <!--          @size-change="handleSizeChange"-->
      <!--          @current-change="handleCurrentChange"-->
      <!--          :current-page="DevicesListInfo.CurrentPage"-->
      <!--          :page-sizes="[5, 10]"-->
      <!--          :page-size="10"-->
      <!--          layout="total, sizes, prev, pager, next, jumper"-->
      <!--          :total="Total">-->
      <!--      </el-pagination>-->




    </el-card>


  </div>
</template>

<script>


//导入moment插件
import moment from 'moment';

//导入axios
import axios from "axios";

import VueQr from 'vue-qr';

import QRCode from 'qrcode'

export default {
  name: "Device",


  components:{
    VueQr,
  },

  props: ['value'],
  data() {



    //验证手机号是否合法
    let isTelphone= (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
            callback(new Error("手机号码格式有误"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入正确的电话号码"));
        }
      }
    };

    return{

      //二维码数据
      imgUrl: 'https://www.baidu.com/',
      logo: require('@/assets/logo.png'),

      howWidth: 8,

      //获取列表单数据（get请求）
      DevicesListInfo: {

      },

      //退款表单数据（post请求）
      addForm: {
        id:Number(this.$route.query.id),
        "refundtotalzengsongjine":0,  //退赠送金额
        "refundtotal":0,   //退总金额
        "reason":""  //退款备注
      },

      //多条件下拉选择器
      select: [{
        value: 1,
        label: '原路退回'
      }, {
        value: 2,
        label: '退回余额'
      }],

      //编辑设备表单数据 (put请求）
      editForm: {

      },







      // 设置并保存设备参数信息的数据
      DevicesList: [],
      Total: 0,

      //控制添加设备对话框显示与隐藏
      addDialog: false,

      //控制编辑设备对话框显示与隐藏
      editDialog: false,

      //控制查看设备对话框显示与隐藏
      checkDialog: false,

      //添加表单验证规则
      addFormRules: {
        refundtotalzengsongjine: [
          { required: true, message: '不可为空' }
        ],
        reason: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        // sjh: [
        //   { required: true, message: '不可为空', trigger: 'change' },
        //   { validator: isTelphone, trigger: "blur" }
        // ],
        refundtotal:[
          { required: true, message: '不能为空'},


        ],
        adminUser: [
          { required: true, message: '不可为空', trigger: 'change' },
          { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }
        ],
        adminPassword: [
          { required: true, message: '不可为空', trigger: 'change' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        adminSjh: [
          { required: true, message: '不可为空', trigger: 'change' },
          { validator: isTelphone, trigger: "blur" }
        ],

      },


      //编辑表单验证规则
      editFormRules: {
        pingtaimingcheng: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        dizhi: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
        kefudianhua: [
          { required: true, message: '不可为空', trigger: 'change' },
          { validator: isTelphone, trigger: "blur" }
        ],
        daoqishijian: [
          { required: true, message: '不可为空', trigger: 'change' }
        ],
      },


      //展示对话框数据（编辑对话框数据源）
      tableData1() {
        return [
          {
            column1:'ID',
            column2:this.editForm.id,
            column3:'订单类型',
            column4:this.editForm.ordertype,
          },
          {
            column1:'创建时间',
            column2:this.editForm.createTime,
            column3:'上次更新时间',
            column4:this.editForm.lastUpdateTime,
          },
          {
            column1:'微信充值订单',
            column2:this.editForm.wxchongzhiorder,
            column3:'订单序列号',
            column4:this.editForm.out_trade_no,
          },
          {
            column1:'支付类型',
            column2:this.editForm.pay_type,
            column3:'支付状态',
            column4:this.editForm.pay_status,
          },
          {
            column1:'赠送金额',
            column2:this.editForm.amount_zengsong,
            column3:'支付金额',
            column4:this.editForm.amount_zhifu,
          },
          {
            column1:'余额',
            column2:this.editForm.amount_yue,
            column3:'优惠金额',
            column4:this.editForm.amount_youhui,
          },
          {
            column1:'用户',
            column2:this.editForm.user,
            column3:'设备号',
            column4:this.editForm.chuifengji,
          },
          {
            column1:'项目',
            column2:this.editForm.xiangmu,
            column3:'微信充值订单',
            column4:this.editForm.wxchongzhiorder,
          },



        ]
      },



    }
  },
  created() {
    // console.log(this.$route.query.id)
    this.getDevicesList(this.$route.query.id)


  },

  mounted() {

    this.getQr()
  },
  methods: {


    //生成二维码带文字描述
    getQr() {

      let c = document.getElementById("myCanvas");
      let ctx = c.getContext("2d");


      let code = 'https://element.eleme.cn/#/zh-CN/component/button'; // 为安全起见，这里就不展示实际code
      QRCode.toCanvas(this.$refs.code, code);

      let img = document.getElementById("qrImg");
      ctx.drawImage(img,6,6,140,140);

      ctx.font = "18px Arial";
      ctx.fillText("编号："+this.$route.query.id,18,160);

    },


    //设备状态格式化函数
    changeStatus(row,column) {
      // row[column.property] 能读取到该行该列的数据
      const DeviceStatus= row[column.property];
      let string = null;
      if(DeviceStatus == 'ONLINE') {
        string ='设备在线';
      }else if(DeviceStatus == 'OFFLINE') {
        string ='设备离线';
      }else if(DeviceStatus == 'UNACTIVE') {
        string ='设备未激活';
      }else if(DeviceStatus == 'DISABLE') {
        string ='设备已禁用';
      }
      return string;
    },

    //日期格式化方法
    dateFormat(row, column) {
      // console.log(row, column)
      const date = row[column.property]
      if (date === undefined) {
        return ''
      }
      // 这里的格式根据需求修改
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },



    //获取列表参数（传入设备id），发起get请求
    async getDevicesList(id) {
      console.log(id)
      await axios({

        method: "get",
        url: '/order/chongzhiorder/'+id+'/',
        //请求设备列表参数对象
        // data: this.DevicesListInfo,

      }).then((res) => {
        console.log(res);
        //获取请求后的数据重新赋值
        const DeviceInfo = (res.data.result);

        //
        // //将请求的数据赋值给data中数据
        this.editForm = DeviceInfo;

        // console.log(DeviceInfo)
        //若status不是200,返回错误消息
        if(res.status !== 200) {
          return this.$message.error('获取列表失败！')
        }
      });

    },



    //监听 pageSize 改变事件
    handleSizeChange(newSize) {
      console.log(newSize);
      this.PageSize = newSize;
    },

    //监听 页码值 改变事件
    async handleCurrentChange(newPage) {
      console.log(newPage);
      await this.getDevicesList(newPage)
    },




    //列表刷新功能
    update(){
      this.$router.go(0)
      this.getDevicesList()
    },

    //返回上一级
    back(){
      this.$router.go(-1)

    },

    //退款，post请求
    refund () {
      this.$refs.addFormRef.validate(async valid => {
        //console.log(valid);
        if (!valid) return;
        //发起添加用户网络请求
        const {data : res} = await this.$http.post('/order/chongzhiorder/refund/',this.addForm)

        if(res.code !== 1) {
          this.$message.error(res.msg);
        } else if(res.code ==1) {
          this.$message.success(res.msg);
        }


        //添加对话框关闭
        this.addDialog = false;



      });
    },

//监听 添加对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },



  },


}


</script>

<style lang="less" scoped>

.status {
  display: inline-flex;
  margin: 30px;
}

//#qrImg {
//  width: 200px!important;height: 200px!important;
//}

</style>
